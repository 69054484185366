.App {
  text-align: center;
}

html, body {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
  background-color: #e8f3d9;
}

div {
  box-sizing: border-box;
}

a {
  color: #62CB31;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: #9bb343;
}

h1 {
  font-size: 52px;
  text-align: center;
}

.home h1 {
  color: white;
  margin-top: 5%;
}

.info h1,
.legal h1 {
  color: #29663d;
  font-size: 26px;
  line-height: 1.3em;
}

.info h2,
.legal h2 {
  font-size: 20px;
  color: #29663d;
  margin: 1.3em 0 0;
}

footer p {
  margin: 0;
}

tr a {
  color: #29663d;
  font-weight: 600;
}

table.menuItems tr:last-child {
  margin-bottom: 100px;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

p.error {
  color: red;
}

p.fine {
  font-size: 12px;
}

p.menu-image-info {
  margin-left: 10px;
  font-size: 12px;
  max-width: 200px;
  color: #58595b;
}

.PhoneInputInput {
  height: 35px;
  padding: 4px 0px 4px 10px;
  border: none;
  border-radius: 8px;
}

#publish-action {
  position: relative;
  display: flex;
  justify-content: flex-start;
  background: none;
  gap: 20px;
  max-height: 46px;
}

div.working-version-label {
  position: absolute;
  height: 12px;
  bottom: -15px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1px;
  font-size: 8px;
  background: rgba(255, 0,0, 0.7);
  text-align: center;
  float: left;
  transition: opacity 1s;
}

div.working-version-label.show {
  opacity: 100;
}

div.working-version-label.hide {
  opacity: 0;
}

div.working-version-label p {
  margin: auto;
  color: black;
}

#version-prompt,
#template-selector {
  position: absolute;
  top: 25vh;
  left: calc(50% - 15vw);
  width: 30vw;
  background: #e8f3d9;
  border: 1px solid white;
  border-radius: 5px;
  box-shadow: 2px 2px 5px grey;
  padding: 15px;
  z-index: 1100;
}

#template-selector {
  text-align: center;
  background-color: white;
  width: 340px;
  left: 3%;
}

#template-selector .design {
  padding: 1em;
  background-color: white;
  margin: 1em;
}

#template-selector .design a {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  min-height: 80px;
}

#template-selector .design.selected {
  border: 2px solid #77c811;
}

#version-prompt h2,
#template-selector h2{
  color: #29663d;
}

.map-frame {
  display: flex;
  column-gap: 1em;
  flex-direction: row;
}

.map-side {
  flex: 1;
  padding: 10px;
  background-color: rgba(255,255,255,0.3);
}

.map-main {
  flex: 4;
}

.map-container {
  height: 78vh;
  width: 100%;
}

#map-info-block {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100px;
  aspect-ratio: 4/1;
  overflow-y: auto;
  background-color: rgba(255,255,255,0.6);
  border: 1px solid black;
}

.info-logo {
  flex: 1;
}

.account-details {
  flex: 3;
  padding: 0 5px;
}

.account-details h3 {
  margin: 3px 0 0 0;
  font-size: .9em;
}

.account-details p {
  margin: 0;
  font-size: .8em;
}

#map-info-block.compact {
  width: 100%;
  height: 80px;
  min-height: 80px;
  cursor: pointer;
}

#map-info-block.compact .account-details h3 {
  margin: 6px 0 0 0;
  line-height: .9em;
  font-size: .8em;
}

#map-info-block.compact .account-details p {
  font-size: .7em;
}

.accounts-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1em;
  margin-top: 1em;
  overflow-y: auto;
}

@media screen and (max-width: 768px) {
  .map-frame {
    flex-direction: column-reverse;
    row-gap: .4em;
  }

  .map-side {
    padding: 2px 0;
  }

  .map-container {
    height: 40vh;
    width: 100%;
  }

  .accounts-list {
    max-height: 160px;
    padding-top: 5px;
    margin-top: .3em;
  }
}

@media screen and (max-width: 570px) {
  .home h1 {
    font-size: 23px;
    margin-top: 6vh;
  }

  .tooltip:hover .tool-tip-text {
    visibility: hidden;
  }
}

@media screen and (max-width: 480px) {
  .home h1 {
    font-size: 18px;
    margin-top: 3vh;
  }
}

/* REACT NOD - see /healthcheck */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.highlight {
  box-shadow: #77c811 3px 3px 10px;
}

.draggable {
  cursor: move;
}

.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tool-tip-text {
  visibility: hidden;
  width: 140px;
  font-size: .6rem;
  background-color: rgba(0,0,0,0.3);
  color: #fff;
  text-align: center;
  padding: 6px;
  margin-left: 1rem;
  border-radius: 6px;
  position: absolute;
  top: 1vh;
  right: 5vw;
  z-index: 1;
}

.tooltip:hover .tool-tip-text {
  visibility: visible;
}