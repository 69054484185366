#California {
    position: relative;
    background-color: white;
    box-sizing: border-box;
    font-family: neuzeit-grotesk, sans-serif;
    padding: 30px;
    margin-top: 16px;
}

#California #outer-frame {
    width: 100%;
    height: 100%;
    border: 2px solid black;
    padding: 5px;
}

#California #inner-frame {
    width: 100%;
    height: 100%;
    border: 1px solid black;
    position: relative;
}

#California #header {
    position: relative;
    display: flex;
    height: 11vw;
    max-height: 180px;
    color: black;
}

#California #account-image {
    position: relative;
    top: -30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    background-color: white;
    margin: auto;
    padding: 1em 2vw;
}

#California #menu-offset {
    position: relative;
    width: 100%;
    text-align: center;
    top: -20px;
}


#California .image-frame {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: white;
    overflow: hidden;
}

#California .image-frame img {
    width: 100%;
}


#California #location-info {
    position: relative;
    width: 100%;
    text-align: center;
    font-size: .85vw;
    line-height: 1.4em;
    padding: 1em;
}

#California #location-address {
    margin: 0;
}

#California #menu-info {
    display: flex;
    flex-direction: column;
    flex: 4;
    text-align: center;
    padding: 1vw;
}

#California #menu-name {
    margin: 0.2em 0;
    font-size: 1.8vw;
    line-height: 1.3em;
    font-weight: bold;
}

#California #menu-description {
    display: inline-block;
    max-width: 80%;
    margin: auto;
    font-size: 1vw;
    line-height: 1.4em;
    font-style: italic;
    overflow-y: auto;
}

#California #location-phone {
    margin-top: 0;
}

#California #categories {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 1vw auto;
    text-align: left;
}

#California #categories section {
    margin: 2vw 0;
}

#California .category-header {
    text-align: center;
    background-image: url(../../assets/images/section-divider.png);
    background-position: center;
    background-repeat: repeat-x;
    max-width: 70%;
    margin: auto;
}

#California .category-header p {
    font-size: 1.3vw;
    letter-spacing: 3px;
    display: inline-block;
    margin: auto;
    padding: 5px 10px;
    background-color: white;
    color: black;
    border-left: 8px solid white;
    border-right: 8px solid white;
}

#California .category-description {
    padding: 10px;
}

#California #menu-items {
    display: flex;
    flex-direction: column;
}

#California .menu-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1vw;
    margin: 1vw 0;
}

#California .menu-item-image {
    position: relative;
    width: 8vw;
    height: 8vw;
    max-width: 150px;
    max-height: 150px;
    min-width: 50px;
    min-height: 50px;
    border: 1px solid #babcbe;
    padding: .2vw;
}

    #California .menu-item-image img {
        width: 100%;
        max-height: calc(162px - .2vw);
        aspect-ratio: 1;
    }

#California .menu-item-info {
    flex: 4;
}

#California .menu-item-name {
    margin: 0;
    font-size: 1.2vw;
    letter-spacing: .1em;
    line-height: 1.3em;
    color: #58595b;
    font-weight: bold;
}

#California .menu-item-description {
    margin: 15px 0;
    font-size: 1vw;
    font-style: italic;
    line-height: 1.3em;
    color: #58595b;
}

#California .plate-items {
    margin: 5px 0 0;
    font-size: .9vw;
    font-style: italic;
    line-height: 1.3em;
    color: #58595b;
}

#California #categories section.option-group {
    margin: 0;
}

#California .option-group-label {
    color: #58595b;
    font-size: .9vw;
    letter-spacing: .1em;
}

#California .option-group-description {
    display: inline-block;
    flex: 4;
    text-align: left;
}

#California .option-group-plate-items {
    margin: 0;
    padding: 2px 1vw;
    list-style: none;
}

#California .option-group-plate-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

#California .tooltip {
    cursor: pointer;
}

#California .tooltip:hover .tool-tip-text {
    visibility: visible;
}

#California .tooltip .tool-tip-text {
    visibility: hidden;
    width: 50%;
    font-size: .6rem;
    background-color: rgba(0,0,0,0.5);
    color: #fff;
    text-align: center;
    padding: 6px;
    margin-left: 1rem;
    border-radius: 6px;
    position: absolute;
    top: -10px;
    left: 30%;
    z-index: 1;
}

#California .option-group-plate-item-name {
    flex: 2;
    font-size: 1vw;
    color: #58595b;
}

#California .option-group-plate-item p{
    margin: 0;
}

#California .option-group-plate-item-description {
    display: inline-block;
    flex: 4;
    font-size: .8vw;
    text-align: left;
    margin: 0;
}

#California .option-group-plate-item-up-charge {
    margin: 0;
    font-size: .9vw;
    text-align: right;
    flex-wrap: nowrap;
}

#California .menu-item-price {
    margin: 0;
    font-size: 1.2vw;
    letter-spacing: .1em;
    line-height: 1.3em;
    color: #58595b;
    font-weight: bold;
}

#California .menu-item-price p {
    margin: 0;
}

@media screen and (min-width: 1700px) {
    #California #header {
        margin-top: 15px;
    }

    #California #menu-name {
        font-size: 30px;
    }

    #California #menu-description {
        font-size: 16px;
    }

    #California #location-info {
        font-size: 15px;
    }

    #California #menu-items {
        gap: 0;
    }

    #California .category-header p {
        font-size: 22px;
    }

    #California .menu-item {
        gap: 2vw;
    }

    #California .menu-item-name {
        font-size: 20px;
    }

    #California .menu-item-description {
        font-size: 16px;
    }

    #California .plate-items {
        margin: 12px 0;
        font-size: 16px;
    }

    #California #categories section.option-group {
        margin: 0;
        padding: 1px 0 0 10px;
    }

    #California #categories section.option-group h2 {
        margin: 0 0 8px 14px;
    }

    #California .option-group-label {
        font-size: 15px;
    }

    #California .option-group-plate-item-name {
        font-size: 16px;
    }

    #California .option-group-plate-item-description {
        font-size: 12px;
    }

    #California .option-group-plate-item-up-charge {
        font-size: 16px;
    }

    #California .menu-item-price {
        font-size: 20px;
    }
}

@media screen and (max-width: 1200px) {
    #California #header {
        height: 18vw;
        margin-top: 15px;
    }

    #California #menu-info {
        padding: 1vw;
    }

    #California #menu-name {
        font-size: 3vw;
    }

    #California #menu-description {
        font-size: 1.5vw;
    }

    #California #location-info {
        font-size: 1.5vw;
        padding: 1em;
    }

    #California #menu-items {
        gap: 2vw;
    }

    #California .menu-item {
        gap: 2vw;
    }

    #California .menu-item-image {
        position: relative;
        width: 16vw;
        height: 16vw;
    }

    #California .category-header p {
        font-size: 2.4vw;
        padding: 5px 6px;
        border-left: 4px solid white;
        border-right: 4px solid white;
    }

    #California .menu-item {
        gap: 2vw;
    }

    #California .menu-item-name {
        font-size: 1.8vw;
    }

    #California .menu-item-description {
        margin: 5px 0;
        font-size: 1.6vw;
    }

    #California .plate-items {
        margin: 12px 0;
        font-size: 1.4vw;
    }

    #California #categories section.option-group {
        margin: 0;
        padding: 1px 0 0 10px;
    }

    #California #categories section.option-group h2 {
        margin: 0 0 .8vw 14px;
    }

    #California .option-group-label {
        font-size: 1.4vw;
    }

    #California .option-group-plate-item {
        display: flex;
        justify-content: space-between;
    }

    #California .option-group-plate-item-name {
        flex: 2;
        font-size: 1.6vw;
    }

    #California .option-group-plate-item-description {
        flex: 4;
        font-size: 1vw;
    }

    #California .option-group-plate-item-up-charge {
        flex: 1;
        font-size: 1.6vw;
    }

    #California .menu-item-price {
        font-size: 1.8vw;
    }
}

@media screen and (max-width: 960px) {
    #California #header {
        height: 18vw;
        margin-top: 15px;
    }

    #California #menu-info {
        padding: 1vw;
    }

    #California #menu-name {
        font-size: 3vw;
    }

    #California #menu-description {
        font-size: 1.5vw;
    }

    #California #location-info {
        font-size: 1.5vw;
        padding: 1em;
    }

    #California #menu-items {
        gap: 2vw;
    }

    #California .menu-item {
        gap: 2vw;
    }

    #California .menu-item-image {
        position: relative;
        width: 16vw;
        height: 16vw;
    }

    #California .category-header p {
        font-size: 2.4vw;
        padding: 5px 6px;
        border-left: 4px solid white;
        border-right: 4px solid white;
    }

    #California .menu-item {
        gap: 2vw;
    }

    #California .menu-item-name {
        font-size: 2.4vw;
    }

    #California .menu-item-description {
        margin: 5px 0;
        font-size: 2vw;
    }

    #California .plate-items {
        margin: 6px 0;
        font-size: 1.8vw;
    }

    #California #categories section.option-group {
        margin: 0;
        padding: 1px 0 0 10px;
    }

    #California #categories section.option-group h2 {
        margin: 0 0 .8vw 10px;
    }

    #California .option-group-label {
        font-size: 1.8vw;
    }

    #California .option-group-plate-item {
        display: flex;
        justify-content: space-between;
    }

    #California .option-group-plate-item-name {
        flex: 2;
        font-size: 2vw;
    }

    #California .option-group-plate-item-description {
        flex: 4;
        font-size: 2vw;
    }

    #California .option-group-plate-item-up-charge {
        flex: 1;
        font-size: 2vw;
    }

    #California .menu-item-price {
        font-size: 2.2vw;
    }
}

@media screen and (max-width: 480px) {
    #California #header {
        height: 22vw;
        margin-top: 15px;
    }

    #California #menu-info {
        padding: 2vw;
    }


    #California #menu-name {
        font-size: 3.5vw;
    }

    #California #menu-description {
        font-size: 2vw;
    }

    #California #location-info {
        font-size: 1.7vw;
        padding: 1em;
    }

    #California #categories {
        width: 100%;
    }

    #California #menu-items {
        gap: 2vw;
    }

    #California .menu-item {
        gap: 2vw;
    }

    #California .menu-item-image {
        position: relative;
        width: 16vw;
        height: 16vw;
    }

    #California .category-header p {
        font-size: 3vw;
        padding: 5px 6px;
        border-left: 4px solid white;
        border-right: 4px solid white;
    }

    #California .menu-item {
        gap: 2vw;
    }

    #California .menu-item-name {
        font-size: 3vw;
    }

    #California .menu-item-description {
        margin: 2px 0;
        font-size: 2.8vw;
    }

    #California .plate-items {
        margin: 0;
        font-size: 2vw;
    }

    #California #categories section.option-group {
        margin: 0;
        padding: 1px 0 0 10px;
    }

    #California #categories section.option-group h2 {
        margin: 0 0 .8vw 5px;
    }

    #California .option-group-label {
        font-size: 2.4vw;
    }

    #California .option-group-plate-item {
        display: flex;
        justify-content: flex-end;
        gap: 2vw;
    }

    #California .option-group-plate-item-name {
        flex: 2;
        font-size: 2.6vw;
    }

    #California .option-group-plate-item-description {
        flex: 2;
        font-size: 2vw;
    }

    #California .option-group-plate-item-up-charge {
        flex: 1;
        font-size: 2.6vw;
        flex-wrap: nowrap;
        flex-shrink: 0;
    }

    #California .menu-item-price {
        font-size: 3vw;
    }
}